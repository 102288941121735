import {useDispatch, useSelector} from "react-redux";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Expandable} from "@pg-design/expandable-module";
import {flexAbsoluteCenter, mh, mt, onDesktop, p, pb, ph, prettyScroll, textAlign} from "@pg-design/helpers-css";
import {ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {IRPStore} from "../../app/rp_reducer";
import {multiLeadApplicationsActionsTypes} from "../../application/actions/multi_lead_applications_actions";
import {MultiLeadApplicationSummary} from "../../application/components/multi_lead/parts/multi_lead_application_summary/MultiLeadApplicationSummary";
import {MultiLeadPromoSection} from "../../application/components/multi_lead_promo/MultiLeadPromoSection";
import {IApplicationData} from "../../application/reducers/application_reducer";
import {IApplication} from "../../application/types/IApplication";
import {IModalStepState} from "../../application/types/IModalStepState";
import {isAbroad} from "../../application/utils/is_abroad";
import {IMarketplaceArchitectOfferSummary} from "../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {useRegionEstimatedPrice} from "../../region/hooks/use_region_estimated_price";
import {formatApplicationCount} from "../utils/application_pluralize";
import {getLeadsSummaryIncludingMainLead} from "../utils/get_leads_summary_including_main_lead";
import {MultiLeadWrapper} from "./MultiLeadWrapper";

type IProps = {
    storeHash: string;
    leadModalStepState: IModalStepState;
    leadData: IApplicationData | null;
    handleSetNextStep: (originalApplicationResponse: IApplication | null) => void;
    handleCloseModal: () => void;
    handleSetArchitectInteriorDesignOfferStep: (architectData: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => void;
    handleSetPmOfferStep: () => void;
};

export function MultiLeadSentSummary(props: IProps) {
    const {leadModalStepState, storeHash, handleSetNextStep, handleCloseModal, handleSetArchitectInteriorDesignOfferStep, handleSetPmOfferStep, leadData} =
        props;

    const {multiLeadSummaryItems, originalApplicationResponse} = leadModalStepState;

    const dispatch = useDispatch();
    const originalApplication = useSelector((store: IRPStore) => store.application[storeHash]);
    const {regionEstimatedPriceSummary} = useRegionEstimatedPrice({
        region: originalApplication?.offer?.detail?.region
    });

    const {summaryItems, sentApplicationCount} = getLeadsSummaryIncludingMainLead({
        leadData,
        originalApplicationResponse,
        multiLeadSummaryItems
    });

    const originalApplicationCountry = originalApplication?.offer?.detail?.region.country;
    const isAbroadLead = isAbroad(originalApplicationCountry);

    const onCloseClick = () => {
        if (summaryItems.length > 0) {
            handleSetNextStep(leadModalStepState.originalApplicationResponse);
        } else {
            handleCloseModal();
        }

        if (isAbroadLead) {
            dispatch({type: multiLeadApplicationsActionsTypes.reset, id: props.storeHash});
        }
    };

    return (
        <MultiLeadWrapper isScrollable prettyScrollStyle css={multiLeadWrapperStyle}>
            <div css={headerStyle} data-testid={OFFER_TEST_IDS.LEAD_FORM.MULTILEAD_SUMMARY_HEADER}>
                <Text variant="headline_5" css={onDesktop(textAlign("center"))}>
                    Wysłano {formatApplicationCount(sentApplicationCount)}
                </Text>
            </div>
            <div css={summaryContentStyle}>
                {summaryItems.length > 1 && !isAbroadLead ? (
                    <Expandable
                        collapsedHeight={220}
                        customCollapseButton={({isExpanded, toggleCollapse}) => (
                            <Button
                                variant="filled_primary"
                                size="x-small"
                                type="button"
                                iconRight={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                                onClick={toggleCollapse}
                                css={[expandableButtonStyle, ph(3)]}
                            >
                                {isExpanded ? "Zwiń podsumowanie" : "Zobacz więcej"}
                            </Button>
                        )}
                    >
                        <MultiLeadApplicationSummary
                            applications={summaryItems}
                            regionEstimatedPriceSummary={regionEstimatedPriceSummary}
                            css={[applicationSummaryWrapper, pb(4)]}
                        />
                    </Expandable>
                ) : (
                    <MultiLeadApplicationSummary
                        applications={summaryItems}
                        regionEstimatedPriceSummary={regionEstimatedPriceSummary}
                        css={applicationSummaryWrapper}
                    />
                )}

                {isAbroadLead ? (
                    <div css={footerStyle}>
                        <Button variant="filled_primary" type="button" css={closeButtonStyle} onClick={onCloseClick}>
                            Zamknij
                        </Button>
                    </div>
                ) : (
                    <MultiLeadPromoSection
                        storeHash={storeHash}
                        onArchitectPackagesClick={handleSetArchitectInteriorDesignOfferStep}
                        onPMOfferClick={handleSetPmOfferStep}
                        css={[p(3, 1.5), onDesktop(p(3))]}
                    />
                )}
            </div>
        </MultiLeadWrapper>
    );
}

// Styles
const multiLeadWrapperStyle = css`
    ${onDesktop(css`
        max-height: 70rem;
    `)};
`;

const headerStyle = css`
    ${p(3, 1.5, 2)};
    flex: 0 0;
`;

const footerStyle = css`
    ${flexAbsoluteCenter};
    ${p(2, 3, 4)};
    flex: 0 0;
`;

const summaryContentStyle = css`
    ${prettyScroll()};
    overflow: auto;
    height: 100%;
`;

const applicationSummaryWrapper = css`
    ${mt(2)};
    ${mh(1.5)};
    flex: 1 1 100%;

    ${onDesktop(css`
        ${mh(3)};
    `)};
`;

const closeButtonStyle = css`
    width: 100%;
    max-width: 30rem;
`;

const expandableButtonStyle = css`
    transform: translateY(-50%);
`;
import {useDispatch, useSelector} from "react-redux";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Expandable} from "@pg-design/expandable-module";
import {flexAbsoluteCenter, mh, mt, onDesktop, p, pb, ph, prettyScroll, textAlign} from "@pg-design/helpers-css";
import {ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {IRPStore} from "../../app/rp_reducer";
import {multiLeadApplicationsActionsTypes} from "../../application/actions/multi_lead_applications_actions";
import {MultiLeadApplicationSummary} from "../../application/components/multi_lead/parts/multi_lead_application_summary/MultiLeadApplicationSummary";
import {MultiLeadPromoSection} from "../../application/components/multi_lead_promo/MultiLeadPromoSection";
import {IApplicationData} from "../../application/reducers/application_reducer";
import {IApplication} from "../../application/types/IApplication";
import {IModalStepState} from "../../application/types/IModalStepState";
import {isAbroad} from "../../application/utils/is_abroad";
import {IMarketplaceArchitectOfferSummary} from "../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {useRegionEstimatedPrice} from "../../region/hooks/use_region_estimated_price";
import {formatApplicationCount} from "../utils/application_pluralize";
import {getLeadsSummaryIncludingMainLead} from "../utils/get_leads_summary_including_main_lead";
import {MultiLeadWrapper} from "./MultiLeadWrapper";

type IProps = {
    storeHash: string;
    leadModalStepState: IModalStepState;
    leadData: IApplicationData | null;
    handleSetNextStep: (originalApplicationResponse: IApplication | null) => void;
    handleCloseModal: () => void;
    handleSetArchitectInteriorDesignOfferStep: (architectData: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => void;
    handleSetPmOfferStep: () => void;
};

export function MultiLeadSentSummary(props: IProps) {
    const {leadModalStepState, storeHash, handleSetNextStep, handleCloseModal, handleSetArchitectInteriorDesignOfferStep, handleSetPmOfferStep, leadData} =
        props;

    const {multiLeadSummaryItems, originalApplicationResponse} = leadModalStepState;

    const dispatch = useDispatch();
    const originalApplication = useSelector((store: IRPStore) => store.application[storeHash]);
    const {regionEstimatedPriceSummary} = useRegionEstimatedPrice({
        region: originalApplication?.offer?.detail?.region
    });

    const {summaryItems, sentApplicationCount} = getLeadsSummaryIncludingMainLead({
        leadData,
        originalApplicationResponse,
        multiLeadSummaryItems
    });

    const originalApplicationCountry = originalApplication?.offer?.detail?.region.country;
    const isAbroadLead = isAbroad(originalApplicationCountry);

    const onCloseClick = () => {
        if (summaryItems.length > 0) {
            handleSetNextStep(leadModalStepState.originalApplicationResponse);
        } else {
            handleCloseModal();
        }

        if (isAbroadLead) {
            dispatch({type: multiLeadApplicationsActionsTypes.reset, id: props.storeHash});
        }
    };

    return (
        <MultiLeadWrapper isScrollable prettyScrollStyle css={multiLeadWrapperStyle}>
            <div css={headerStyle} data-testid={OFFER_TEST_IDS.LEAD_FORM.MULTILEAD_SUMMARY_HEADER}>
                <Text variant="headline_5" css={onDesktop(textAlign("center"))}>
                    Wysłano {formatApplicationCount(sentApplicationCount)}
                </Text>
            </div>
            <div css={summaryContentStyle}>
                {summaryItems.length > 1 && !isAbroadLead ? (
                    <Expandable
                        collapsedHeight={220}
                        customCollapseButton={({isExpanded, toggleCollapse}) => (
                            <Button
                                variant="filled_primary"
                                size="x-small"
                                type="button"
                                iconRight={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                                onClick={toggleCollapse}
                                css={[expandableButtonStyle, ph(3)]}
                            >
                                {isExpanded ? "Zwiń podsumowanie" : "Zobacz więcej"}
                            </Button>
                        )}
                    >
                        <MultiLeadApplicationSummary
                            applications={summaryItems}
                            regionEstimatedPriceSummary={regionEstimatedPriceSummary}
                            css={[applicationSummaryWrapper, pb(4)]}
                        />
                    </Expandable>
                ) : (
                    <MultiLeadApplicationSummary
                        applications={summaryItems}
                        regionEstimatedPriceSummary={regionEstimatedPriceSummary}
                        css={applicationSummaryWrapper}
                    />
                )}

                {isAbroadLead ? (
                    <div css={footerStyle}>
                        <Button variant="filled_primary" type="button" css={closeButtonStyle} onClick={onCloseClick}>
                            Zamknij
                        </Button>
                    </div>
                ) : (
                    <MultiLeadPromoSection
                        storeHash={storeHash}
                        onArchitectPackagesClick={handleSetArchitectInteriorDesignOfferStep}
                        onPMOfferClick={handleSetPmOfferStep}
                        css={[p(3, 1.5), onDesktop(p(3))]}
                    />
                )}
            </div>
        </MultiLeadWrapper>
    );
}

// Styles
const multiLeadWrapperStyle = css`
    ${onDesktop(css`
        max-height: 70rem;
    `)};
`;

const headerStyle = css`
    ${p(3, 1.5, 2)};
    flex: 0 0;
`;

const footerStyle = css`
    ${flexAbsoluteCenter};
    ${p(2, 3, 4)};
    flex: 0 0;
`;

const summaryContentStyle = css`
    ${prettyScroll()};
    overflow: auto;
    height: 100%;
`;

const applicationSummaryWrapper = css`
    ${mt(2)};
    ${mh(1.5)};
    flex: 1 1 100%;

    ${onDesktop(css`
        ${mh(3)};
    `)};
`;

const closeButtonStyle = css`
    width: 100%;
    max-width: 30rem;
`;

const expandableButtonStyle = css`
    transform: translateY(-50%);
`;
